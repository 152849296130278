body {
    background-color: #F0F8FF;
    color: #003366;
}

.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

label {
    font-weight: bold;
    margin-right: 10px;
}

input[type="text"] {
    padding: 6px;
    margin: 3px;
    border: 1px solid #3506b8;
    border-radius: 4px;
  }

  button {
    background-color: #B1D4E0;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
  }

  .events-container {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
  }

button:hover {
    background-color: #0056b3;
}

.ButtonGroup {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.ButtonGroup button {
    flex-grow: 1;
    text-align: center;
}

.selected-button {
    background-color: #145DA0;
}

.clearAllButtonContainer {
    text-align: center;
    margin-top: 20px;
}

.clearAllButton {
    width: 100%;
    font-size: 20px;
    padding: 8px 16px;
    background-color: #B1D4E0;
    color: white;
    border: 2px solid #145DA0;
    border-radius: 15px;
    cursor: pointer;
  }
.clearAllButton:hover {
    background-color: #0056b3;
}

