/* App.css */
.App-header {
  text-align: center;
}

.homePageHeader {
  text-align: center;
}

.Form-def {
  background-color: white;
}

.customButton {
  letter-spacing: 0.5px;
  background-color: #B1D4E0;
  border: 2px solid #145DA0;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.active {
  background-color: #145DA0;
  border: 1.5px solid rgb(25, 26, 24);
}

.homePageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 300px;
}

.homePageButton,
.contactButton {
  background-color: #145DA0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
}

.homePageButton:hover,
.contactButton:hover {
  background-color: #0056b3;
}